var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import StatisticsHeader from 'modules/statistics/components/StatisticsHeader.vue';
// import StatisticsChart from '@/modules/statistics/components/StatisticsChart.vue';
import StatisticsChart from '@/modules/statistics/components/StatisticsChart.vue';
import StatisticsTable from '@/modules/statistics/components/StatisticsTable.vue';
import StatisticsTableFiltersMenu from '../components/StatisticsTableFiltersMenu.vue';
let StatisticsContainer = class StatisticsContainer extends Vue {
    constructor() {
        super(...arguments);
        this.showDetailsByPeriod = false;
        this.tableColumns = {
            Ligações: true,
            'Contato com decisor': true,
            'Reuniões agendadas': true,
            'Não iniciados': true,
            'Contatos iniciados': true,
            Descartados: true,
            'Contatos interessados': true,
            'Contatos sucessos': true,
            '% Ligações x Decisor': true,
            '% Ligações x Reuniões': true,
            '% Decisor x Reuniões': true,
        };
    }
    toggleColumn(columnName, value) {
        this.tableColumns[columnName] = value;
    }
};
StatisticsContainer = __decorate([
    Component({
        name: 'StatisticsContainer',
        components: {
            StatisticsHeader,
            StatisticsChart,
            StatisticsTable,
            StatisticsTableFiltersMenu,
        },
    })
], StatisticsContainer);
export default StatisticsContainer;
