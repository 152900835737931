var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import { date } from 'quasar';
let StatisticsHeaderDatePicker = class StatisticsHeaderDatePicker extends Vue {
    constructor() {
        super(...arguments);
        this.dateRangeLabel = new String(this.rangeLabel).toString();
        this.rangeValues = [...this.dateValues];
        this.customRangeLabel = '';
    }
    selectToday() {
        this.dateRangeLabel = 'Hoje';
        //this.$emit('dateSelected', [new Date(), new Date()]);
        this.rangeValues = [new Date(), new Date()];
    }
    selectYesterday() {
        this.dateRangeLabel = 'Ontem';
        const date = new Date();
        date.setTime(date.getTime() - 3600 * 1000 * 24);
        //this.$emit('dateSelected', [date, date]);
        this.rangeValues = [date, date];
    }
    selectLastDays(days) {
        this.dateRangeLabel = `Últimos ${days} dias`;
        let today = new Date();
        const begin = date.subtractFromDate(today, { days });
        const end = date.subtractFromDate(today, { days: 1 });
        //this.$emit('dateSelected', [begin, end]);
        this.rangeValues = [begin, end];
    }
    close() {
        this.$emit('cancel', true);
    }
    updateDate() {
        if (this.customRangeLabel !== '') {
            this.$emit('dateSelected', this.rangeValues, this.customRangeLabel);
        }
        else {
            this.$emit('dateSelected', this.rangeValues, this.dateRangeLabel);
        }
    }
    updateRangeLabel(dataValues) {
        const beginDateLabel = date.formatDate(dataValues[0], 'DD-MM-YYYY');
        const endDateLabel = date.formatDate(dataValues[1], 'DD-MM-YYYY');
        this.customRangeLabel = `${beginDateLabel} - ${endDateLabel}`;
    }
};
__decorate([
    Prop({
        default: () => [new Date(), new Date()],
    })
], StatisticsHeaderDatePicker.prototype, "dateValues", void 0);
__decorate([
    Prop({ default: 'Hoje' })
], StatisticsHeaderDatePicker.prototype, "rangeLabel", void 0);
StatisticsHeaderDatePicker = __decorate([
    Component({
        name: 'StatisticsHeaderDatePicker',
    })
], StatisticsHeaderDatePicker);
export default StatisticsHeaderDatePicker;
