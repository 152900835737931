var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
let StatisticsTable = class StatisticsTable extends Vue {
    constructor() {
        super(...arguments);
        this.columnsList = [];
        this.userProspectData = [
            {
                name: 'Joao da Silva',
                phoneCalls: 449,
                decisorContacts: 52,
                scheduleMeetings: 11,
                notStartedContacts: 123,
                startedContacts: 123,
                discardedContacts: 22,
                interestedContacts: 44,
                successContacts: 19,
                percentDecisorCalls: '12,22%',
                percentMeetingCalls: '17,12%',
                percentDecisorMeetings: '22,16%',
                color: 'blue',
            },
            {
                name: 'Maria Sousa',
                phoneCalls: 454,
                decisorContacts: 94,
                scheduleMeetings: 11,
                notStartedContacts: 123,
                startedContacts: 123,
                discardedContacts: 22,
                interestedContacts: 11,
                successContacts: 19,
                percentDecisorCalls: '22,23%',
                percentMeetingCalls: '12,23%',
                percentDecisorMeetings: '16,23%',
                color: 'blue lighten-2',
            },
            {
                name: 'Leandro Aguilar',
                phoneCalls: 462,
                decisorContacts: 59,
                scheduleMeetings: 11,
                notStartedContacts: 123,
                startedContacts: 123,
                discardedContacts: 22,
                interestedContacts: 11,
                successContacts: 19,
                percentDecisorCalls: '12,22%',
                percentMeetingCalls: '17,12%',
                percentDecisorMeetings: '22,16%',
                color: 'deep-purple darken-4',
            },
            {
                name: 'Diogo Publio',
                phoneCalls: 398,
                decisorContacts: 66,
                scheduleMeetings: 11,
                notStartedContacts: 123,
                startedContacts: 123,
                discardedContacts: 22,
                interestedContacts: 11,
                successContacts: 19,
                percentDecisorCalls: '22,23%',
                percentMeetingCalls: '12,23%',
                percentDecisorMeetings: '16,23%',
                color: 'teal lighten-2',
            },
            {
                name: 'Lucas Alvarez',
                phoneCalls: 350,
                decisorContacts: 78,
                scheduleMeetings: 39,
                notStartedContacts: 123,
                startedContacts: 123,
                discardedContacts: 22,
                interestedContacts: 11,
                successContacts: 19,
                percentDecisorCalls: '12,22%',
                percentMeetingCalls: '17,12%',
                percentDecisorMeetings: '22,16%',
                color: 'purple accent-4',
            },
        ];
        this.performance = [
            { label: 'Ligações', value: false },
            { label: 'Contato com decisor', value: false },
            { label: 'Reuniões agendada', value: false },
        ];
        this.contacts = [
            { label: 'Não iniciados', value: false },
            { label: 'Contatos iniciados', value: false },
            { label: 'Descartados', value: false },
            { label: 'Contatos interessados', value: false },
            { label: 'Contatos sucessos', value: false },
        ];
        this.percentages = [
            { label: '% Ligações x Decisor', value: false },
            { label: '% Ligações x Reuniões', value: false },
            { label: '% Decisor x Reuniões', value: false },
        ];
    }
    getColumnName(columnObj) {
        return Object.keys(columnObj)[0];
    }
    getColumnsList(columns) {
        this.columnsList = Object.entries(columns).map(columnItem => {
            return { label: columnItem[0], value: columnItem[1] };
        });
    }
    onColumnsChange(val, oldVal) {
        this.getColumnsList(val);
    }
    mounted() {
        this.getColumnsList(this.columns);
    }
};
__decorate([
    Prop({ default: () => { } })
], StatisticsTable.prototype, "columns", void 0);
__decorate([
    Watch('columns', { deep: true })
], StatisticsTable.prototype, "onColumnsChange", null);
StatisticsTable = __decorate([
    Component({
        name: 'StatisticsTable',
    })
], StatisticsTable);
export default StatisticsTable;
