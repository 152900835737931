var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import Input from 'components/Input.vue';
let StatisticsTableFiltersMenu = class StatisticsTableFiltersMenu extends Vue {
    constructor() {
        super(...arguments);
        this.columnsGroup = [
            {
                label: 'Desempenho',
                items: [
                    { label: 'Ligações', value: true },
                    { label: 'Contrato com decisor', value: true },
                    { label: 'Reuniões agendadas', value: true },
                ],
            },
            {
                label: 'Contatos',
                items: [
                    { label: 'Não iniciados', value: true },
                    { label: 'Contatos iniciados', value: true },
                    { label: 'Descartados', value: true },
                    { label: 'Contatos interessados', value: true },
                    { label: 'Contatos sucessos', value: true },
                ],
            },
            {
                label: 'Usuários',
                items: [
                    { label: '% Ligações x Decisor', value: true },
                    { label: '% Ligações x Reuniões', value: true },
                    { label: '% Decisor x Reuniões', value: true },
                ],
            },
        ];
    }
    toggleColumn(columnName, value) {
        this.columnsGroup = this.columnsGroup.map(column => {
            column.items = column.items.map(item => {
                if (columnName === item.label) {
                    return { label: item.label, value: !value };
                }
                return { label: item.label, value: item.value };
            });
            return column;
        });
        this.$emit('toggleColumn', columnName, !value);
    }
};
StatisticsTableFiltersMenu = __decorate([
    Component({
        name: 'StatisticsTableFiltersMenu',
        components: {
            Input,
        },
    })
], StatisticsTableFiltersMenu);
export default StatisticsTableFiltersMenu;
