var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
let StatisticsChartFilters = class StatisticsChartFilters extends Vue {
    constructor() {
        super(...arguments);
        this.filtersGroup = {
            selectedItem: 'Ligações',
            items: [
                {
                    id: 0,
                    label: 'Desempenho',
                    items: [
                        { label: 'Ligações' },
                        { label: 'Contrato com decisor' },
                        { label: 'Reuniões agendadas' },
                    ],
                },
                {
                    id: 1,
                    label: 'Contatos',
                    items: [
                        { label: 'Não iniciados' },
                        { label: 'Contatos iniciados' },
                        { label: 'Descartados' },
                        { label: 'Contatos interessados' },
                        { label: 'Contatos sucessos' },
                    ],
                },
                {
                    id: 2,
                    label: 'Usuários',
                    items: [
                        { label: '% Ligações x Decisor' },
                        { label: '% Ligações x Reuniões' },
                        { label: '% Decisor x Reuniões' },
                    ],
                },
            ],
        };
    }
    get dropdownLabel() {
        return `Gráfico por ${this.filtersGroup.selectedItem}`;
    }
    toggleFilter(label) {
        this.filtersGroup.selectedItem = label;
    }
};
StatisticsChartFilters = __decorate([
    Component({ name: 'StatisticsChartFilters' })
], StatisticsChartFilters);
export default StatisticsChartFilters;
