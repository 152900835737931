var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';
import StatisticsChartFilters from '@/modules/statistics/components/StatisticsChartFilters.vue';
let StatisticsChart = class StatisticsChart extends Vue {
    constructor() {
        super(...arguments);
        this.chartOptions = {
            chart: {
                type: 'line',
                height: 280,
            },
            title: {
                text: '',
            },
            yAxis: {
                title: {
                    text: '',
                },
            },
            xAxis: {
                dataTimeLabelFormats: {
                    day: {
                        main: 'Nov1. Nov2',
                    },
                },
            },
            legend: {
                enabled: false,
            },
            series: [
                {
                    name: 'Installation',
                    data: [449, 52, 11, 123, 19, 22, 56, 12.22, 17.22, 22.16],
                    color: 'blue',
                },
                {
                    name: 'Manufacturing',
                    data: [350, 25, 19, 34, 22, 45, 56, 12.11, 16.22, 20.16],
                },
                {
                    name: 'Sales & Distribution',
                    data: [200, 36, 9, 90, 19, 13, 87, 8.22, 11.22, 32.16],
                },
                {
                    name: 'Project Development',
                    data: [398, 24, 30, 42, 17, 32, 66, 22.22, 27.22, 32.16],
                },
                {
                    name: 'Other',
                    data: [200, 42, 8, 133, 29, 52, 36, 32.22, 13.22, 15.16],
                },
            ],
        };
    }
};
StatisticsChart = __decorate([
    Component({
        name: 'StatisticsChart',
        components: {
            highcharts: Chart,
            StatisticsChartFilters,
        },
    })
], StatisticsChart);
export default StatisticsChart;
