var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Emit } from 'vue-property-decorator';
import StatisticsHeaderFiltersMenu from './StatisticsHeaderFiltersMenu.vue';
import StatisticsHeaderDatePicker from './StatisticsHeaderDatePicker.vue';
import StatisticsFiltersLoadMenu from './StatisticsFiltersLoadMenu.vue';
import Input from '@/components/Input.vue';
let StatisticsHeader = class StatisticsHeader extends Vue {
    constructor() {
        super(...arguments);
        this.filterActive = false;
        this.rangeDateToggle = 'day';
        this.dateRangeLabel = 'Hoje';
        this.dateRange = [new Date(), new Date()];
    }
    changeRangeDate(value) {
        // this.rangeDate = value;
        // return this.rangeDate;
    }
    toggleFilter() {
        this.filterActive = !this.filterActive;
    }
    changeRangeSelected(dateRange, updatedLabel) {
        this.dateRangeLabel = updatedLabel;
        this.dateRange = dateRange;
        // @ts-ignore
        this.$refs.dropdownMenu.hide();
    }
    closeDatePickerMenu() {
        // @ts-ignore
        this.$refs.dropdownMenu.hide();
    }
};
__decorate([
    Emit('changeRangeDate')
], StatisticsHeader.prototype, "changeRangeDate", null);
StatisticsHeader = __decorate([
    Component({
        name: 'StatisticsHeader',
        components: {
            Input,
            StatisticsHeaderFiltersMenu,
            StatisticsHeaderDatePicker,
            StatisticsFiltersLoadMenu,
        },
    })
], StatisticsHeader);
export default StatisticsHeader;
